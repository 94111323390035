import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import "./App.css"
import Page from './page';

function ScrollToTop() {
  const { pathname } = useLocation();





  useEffect(() => {
    window.scrollTo(0, 0);




  }, [pathname]);

  return null;
}


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          
          <Route path='/:id' element={<Page />} />
        
        </Routes>


      </BrowserRouter>
    </div>
  );
}

export default App;